function scrollt () {
		var doc = document.documentElement;
		var scrollHeight = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
		var headerh = document.querySelector('header').offsetHeight;
		var windowh = window.innerHeight	|| document.documentElement.clientHeight	|| document.body.clientHeight;
		if(scrollHeight > (windowh-headerh)) {
			if (document.querySelector('.inlinePage')) { 
			
			} else {
				//document.querySelector('.header').classList.remove("pos-a", "lb0", "headerbottom");
				//document.querySelector('.header').classList.add("lt0", "pos-f", "headertop");
			}
			var toggle = document.querySelector(".toggle")
			toggle.onmouseover = function(event) {
			 //document.getElementsByClassName("sub-content")[0].style.height = 80 + 'px';
			 //document.getElementsByClassName("sub-content")[0].style.top = headerh + 'px';
			};

			toggle.onmouseout = function(event) {
			  document.getElementsByClassName("sub-content")[0].style.height = 0 + 'px';
			};


		}

		if (document.querySelector('#vitrin')) {

			/*if(scrollHeight > (windowh/2)) {
				document.querySelector('#vitrin .item1').classList.remove('scrollhide');
				document.querySelector('#vitrin .item1').classList.add('scrollvisible');
			}

			if(scrollHeight > (windowh+(windowh/2))) {
				document.querySelector('#vitrin .item2').classList.remove('scrollhide');
				document.querySelector('#vitrin .item2').classList.add('scrollvisible');

				document.querySelector('#vitrin .item1').classList.add('scrollhide');
				document.querySelector('#vitrin .item1').classList.remove('scrollvisible');
			}

			if(scrollHeight > ((windowh*2)+(windowh/2))) {
				document.querySelector('#vitrin .item3').classList.remove('scrollhide');
				document.querySelector('#vitrin .item3').classList.add('scrollvisible');

				document.querySelector('#vitrin .item2').classList.add('scrollhide');
				document.querySelector('#vitrin .item2').classList.remove('scrollvisible');
			}

			if(scrollHeight > ((windowh*3)+(windowh/5*1))) {
				document.querySelector('#vitrin .item4').classList.remove('scrollhide');
				document.querySelector('#vitrin .item4').classList.add('scrollvisible');
			}

			if(scrollHeight > (windowh*4)) {
				document.querySelector('#vitrin .item5').classList.remove('scrollhide');
				document.querySelector('#vitrin .item5').classList.add('scrollvisible');
			}

			if(scrollHeight > (windowh*5)) {
				document.querySelector('#vitrin .item6').classList.remove('scrollhide');
				document.querySelector('#vitrin .item6').classList.add('scrollvisible');
			}
			*/
		}

		if(scrollHeight < (windowh-headerh)) {
			if (document.querySelector('.inlinePage')) { 
			
			} else {
				//document.querySelector('.header').classList.add("pos-a", "lb0", "headerbottom");
				//document.querySelector('.header').classList.remove("lt0", "pos-f", "headertop");
			}
			if (document.querySelector('.toggle')) {
				var toggle = document.querySelector(".toggle")
				toggle.onmouseover = function(event) {
				 document.getElementsByClassName("sub-content")[0].style.height = 80 + 'px';
				 //document.getElementsByClassName("sub-content")[0].style.bottom = headerh + 'px';
				 //document.getElementsByClassName("sub-content")[0].style.top = "initial";
				};

				toggle.onmouseout = function(event) {
				  document.getElementsByClassName("sub-content")[0].style.height = 0 + 'px';
				};
			}

		}

		if (document.querySelector('#vitrin')) {
			/*if(scrollHeight < (windowh/2)) {
				document.querySelector('#vitrin .item1').classList.add('scrollhide');
				document.querySelector('#vitrin .item1').classList.remove('scrollvisible');
			}

			if(scrollHeight < (windowh+(windowh/2))) {
				document.querySelector('#vitrin .item2').classList.add('scrollhide');
				document.querySelector('#vitrin .item2').classList.remove('scrollvisible');
			}

			if(scrollHeight < ((windowh*2)+(windowh/2))) {
				document.querySelector('#vitrin .item3').classList.add('scrollhide');
				document.querySelector('#vitrin .item3').classList.remove('scrollvisible');
			}

			if(scrollHeight < ((windowh*3)+(windowh/5*1))) {
				document.querySelector('#vitrin .item4').classList.add('scrollhide');
				document.querySelector('#vitrin .item4').classList.remove('scrollvisible');
			}

			if(scrollHeight < (windowh*4)) {
				document.querySelector('#vitrin .item5').classList.add('scrollhide');
				document.querySelector('#vitrin .item5').classList.remove('scrollvisible');
			}

			if(scrollHeight < (windowh*5)) {
				document.querySelector('#vitrin .item6').classList.add('scrollhide');
				document.querySelector('#vitrin .item6').classList.remove('scrollvisible');
			}
			*/
		}


	}
	var lastScrollTop = 0;
	window.addEventListener("scroll", function(event) {
			 var st = this.scrollY;
		   if (st > lastScrollTop) {
		     scrollt();
		   } else {
		     scrollt();
		   }
		   lastScrollTop = st;
	}, false);

/* ========== Vitrin Contact Alanı ========== */
	if (document.querySelector('.contactarea')) {
		var mapLink = document.querySelector(".nav-link.map")
		var formLink = document.querySelector(".nav-link.form")


		formLink.addEventListener("click", function(){
			document.querySelector('.nav-link.map').classList.remove('active');
			document.querySelector('.nav-link.form').classList.add('active');
			document.querySelector('.tab-item.mapcontent').style.display = 'none';
			document.querySelector('.tab-item.formcontent').style.display = 'block';
			document.querySelector('.tab-item.formcontent').style.opacity = '0';
			setTimeout(function(){
				document.querySelector('.tab-item.formcontent').style.opacity = '10';
			}, 200);
		});

		mapLink.addEventListener("click", function(){
			document.querySelector('.nav-link.form').classList.remove('active');
			document.querySelector('.nav-link.map').classList.add('active');
			document.querySelector('.tab-item.formcontent').style.display = 'none';
			document.querySelector('.tab-item.mapcontent').style.display = 'block';
			document.querySelector('.tab-item.mapcontent').style.opacity = '0';
			setTimeout(function(){
				document.querySelector('.tab-item.mapcontent').style.opacity = '10';
			}, 200);
		});
	}
	/* ========== Vitrin Contact Alanı End ========== */


// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
	document.querySelector('html').classList.remove('owh');
	var windowh = window.innerHeight;
	var headerh = document.querySelector('.header').offsetHeight;
	var footerh = document.querySelector('footer').offsetHeight;

	/* ========== Footer Fixed ========== */
	var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
	if (viewportWidth > 992) {
		if (document.querySelector('.footerFixed')) {
			document.getElementsByClassName("footerFixed")[0].style.minHeight = windowh-footerh + 'px';
			//document.getElementsByClassName("footerFixed")[0].style.paddingTop = headerh + 'px';
		}	
		if (document.querySelector('.contentFixed')) {
			document.getElementsByClassName("contentFixed")[0].style.minHeight = windowh + 'px';
			//document.getElementsByClassName("contentFixed")[0].style.paddingTop = headerh + 'px';
		}	
	} else {
		if (document.querySelector('.footerFixed')) {
			document.getElementsByClassName("footerFixed")[0].style.minHeight = windowh-footerh + 'px';
		}	
		if (document.querySelector('.contentFixed')) {
			document.getElementsByClassName("contentFixed")[0].style.minHeight = windowh + 'px';
		}	
	}
	/* ========== Footer Fixed End ========== */



	var toggle = document.querySelector(".toggle")
	toggle.onmouseover = function(event) {

		var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
		if (viewportWidth > 992) {
			document.getElementsByClassName("sub-content")[0].style.height = 80 + 'px';
		} else {
			document.getElementsByClassName("sub-content")[0].style.height = 416 + 'px';
			document.querySelector('.toggle i.icon').classList.add('block');
		}
		
		document.getElementsByClassName("sub-content")[0].style.bottom = headerh + 'px';
	};
	toggle.onmouseout = function(event) {
	  document.getElementsByClassName("sub-content")[0].style.height = 0 + 'px';
	  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
	  if (viewportWidth < 992) {
	  	document.querySelector('.toggle i.icon').classList.remove('block');

	  }
	};
	/* ========== Banner Alanı ========== */
	if (document.querySelector('.bannerh')) {
		document.getElementsByClassName("bannerh")[0].style.height = windowh-headerh + 'px';
	}	
	/* ========== Banner Alanı End ========== */
	/* ========== Vitrin Contact Alanı ========== */
	if (document.querySelector('.contactarea')) {
		var mapLink = document.querySelector(".nav-link.map")
		var formLink = document.querySelector(".nav-link.form")


		formLink.addEventListener("click", function(){
			document.querySelector('.nav-link.map').classList.remove('active');
			document.querySelector('.nav-link.form').classList.add('active');
			document.querySelector('.tab-item.mapcontent').style.display = 'none';
			document.querySelector('.tab-item.formcontent').style.display = 'block';
			document.querySelector('.tab-item.formcontent').style.opacity = '0';
			setTimeout(function(){
				document.querySelector('.tab-item.formcontent').style.opacity = '10';
			}, 200);
		});

		mapLink.addEventListener("click", function(){
			document.querySelector('.nav-link.form').classList.remove('active');
			document.querySelector('.nav-link.map').classList.add('active');
			document.querySelector('.tab-item.formcontent').style.display = 'none';
			document.querySelector('.tab-item.mapcontent').style.display = 'block';
			document.querySelector('.tab-item.mapcontent').style.opacity = '0';
			setTimeout(function(){
				document.querySelector('.tab-item.mapcontent').style.opacity = '10';
			}, 200);
		});
	}
	/* ========== Vitrin Contact Alanı End ========== */
	/* ========== Contact Alanı ========== */
	if (document.querySelector('#contact')) {
	//	var formclose = document.querySelector(".contact2 .formclose")
	//	var contact_formLink = document.querySelector(".formlink")


	//	contact_formLink.addEventListener("click", function(){
	//		setTimeout(function(){
	//			document.querySelector('.formclose').classList.add('rt180');
	//		}, 250);
	//		
	//		document.querySelector('#contact .contact1').style.display = 'none';
	//		document.querySelector('#contact .contact2').style.display = 'block';
	//		document.querySelector('#contact .contact2').style.opacity = '0';
	//		setTimeout(function(){
	//			document.querySelector('#contact .contact2').style.opacity = '10';
	//		}, 200);
	//		
	//	});
//
//		formclose.addEventListener("click", function(){
//			document.querySelector('.formclose').classList.remove('rt180');
//			document.querySelector('#contact .contact2').style.display = 'none';
//			document.querySelector('#contact .contact1').style.display = 'block';
//			document.querySelector('#contact .contact1').style.opacity = '0';
//			setTimeout(function(){
//				document.querySelector('#contact .contact1').style.opacity = '10';
//			}, 200);
//			
//		});

}
	/* ========== Contact Alanı End ========== */
	/* ========== Mobil Menü Toggle ========== */
	var MenuToggle = document.querySelector("#menuToggle .toggle")
	var MenuCloser = document.querySelector("#menuCloser")	



	MenuToggle.addEventListener("click", function(){
		document.querySelector('#menuToggle .toggle').classList.add('rt180');
		document.querySelector('#menuToggle .toggle .icon-menu').classList.add('none');
		document.querySelector('#menuToggle .toggle .icon-cikar2').classList.add('block');
		document.querySelector('header').classList.add('ml0');
		document.querySelector('#menuCloser').classList.add('mr0');
		document.querySelector('main').classList.add('soft-slow','gray','owh-x');
		document.querySelector('html').classList.add('owh');
	});
	MenuCloser.addEventListener("click", function(){
		document.querySelector('header').classList.remove('ml0');
		document.querySelector('#menuCloser').classList.remove('mr0');
		document.querySelector('#menuToggle .toggle').classList.remove('rt180');
		document.querySelector('#menuToggle .toggle .icon-menu').classList.remove('none');
		document.querySelector('#menuToggle .toggle .icon-cikar2').classList.remove('block');
		document.querySelector('main').classList.remove('soft','gray','owh-x');
		document.querySelector('html').classList.remove('owh');
	});

	/* ========== Mobil Menü Toggle End ========== */
	if (document.querySelector('.inlinePage')) { 
		var innerPage = document.querySelector('.inlinePage').parentElement.parentElement.parentElement.parentElement.nodeName;
		document.querySelector(innerPage).classList.add('innercontent');
		document.querySelector('header').classList.remove('pos-a','lb0');
		document.querySelector('header').classList.add('pos-f','lt0');
		toggle.onmouseover = function(event) {
		 document.getElementsByClassName("sub-content")[0].style.height = 80 + 'px';
		 document.getElementsByClassName("sub-content")[0].style.top = headerh + 'px';
		};

		toggle.onmouseout = function(event) {
		  document.getElementsByClassName("sub-content")[0].style.height = 0 + 'px';
		};
	}

	if (document.querySelector('.mainPage')) { 
		document.querySelector('body').classList.remove('innercontent');
	}



	






}


	


