// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-js": () => import("./../../../src/pages/corporate.js" /* webpackChunkName: "component---src-pages-corporate-js" */),
  "component---src-pages-cozumler-js": () => import("./../../../src/pages/cozumler.js" /* webpackChunkName: "component---src-pages-cozumler-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-gizlilik-js": () => import("./../../../src/pages/gizlilik.js" /* webpackChunkName: "component---src-pages-gizlilik-js" */),
  "component---src-pages-id-js": () => import("./../../../src/pages/ID.js" /* webpackChunkName: "component---src-pages-id-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kimlik-js": () => import("./../../../src/pages/kimlik.js" /* webpackChunkName: "component---src-pages-kimlik-js" */),
  "component---src-pages-kurumsal-js": () => import("./../../../src/pages/kurumsal.js" /* webpackChunkName: "component---src-pages-kurumsal-js" */),
  "component---src-pages-ozelyazilim-js": () => import("./../../../src/pages/ozelyazilim.js" /* webpackChunkName: "component---src-pages-ozelyazilim-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-ru-js": () => import("./../../../src/pages/ru.js" /* webpackChunkName: "component---src-pages-ru-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-urunler-js": () => import("./../../../src/pages/urunler.js" /* webpackChunkName: "component---src-pages-urunler-js" */),
  "component---src-pages-безопасность-js": () => import("./../../../src/pages/безопасность.js" /* webpackChunkName: "component---src-pages-безопасность-js" */),
  "component---src-pages-идентификация-js": () => import("./../../../src/pages/идентификация.js" /* webpackChunkName: "component---src-pages-идентификация-js" */),
  "component---src-pages-корпоративный-js": () => import("./../../../src/pages/корпоративный.js" /* webpackChunkName: "component---src-pages-корпоративный-js" */),
  "component---src-pages-продукты-js": () => import("./../../../src/pages/продукты.js" /* webpackChunkName: "component---src-pages-продукты-js" */),
  "component---src-pages-решения-js": () => import("./../../../src/pages/решения.js" /* webpackChunkName: "component---src-pages-решения-js" */),
  "component---src-pages-связь-js": () => import("./../../../src/pages/связь.js" /* webpackChunkName: "component---src-pages-связь-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */)
}

